import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPageData,
  resetMenuData,
} from "../../../../redux/slice/homeSlice/sliceHome";
import { Link } from "react-router-dom";

const Welcome = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchPageData(`get-page-details/1`));
    return () => {
      dispatch(resetMenuData());
    };
  }, [dispatch]);

  const { pageData, loading } = useSelector((state) => state.sliceHome);
  return (
    <>
      <div className="intro-area mt-5">
        <div className="container">
          <div className="row light-graybg p-3 pb-5">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12">
              <div className="wecome-sec text-center">
                <h2 className="httext">
                  Welcome <span className="ht-2"> Dharma</span>
                </h2>
                <div className="d-txt">
                  <p
                    dangerouslySetInnerHTML={{
                      __html:
                        pageData.data && pageData.data.content.slice(0, 300),
                    }}
                  />
                </div>
                <Link to="/about-us" className="read-more-btn">
                  Read More
                </Link>
              </div>
            </div>
            {/* <div className="col-12 col-sm-12 col-md-6 col-lg-6">
              <div className="cards-sec">
                <a href="">
                  <div className="card cl-1">
                    <div className="c-head">
                      <h5>₹2,100 crores</h5>
                      <div className="img-p">
                        <img
                          src="images/c-1.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <h6>Estimated Cost for</h6>
                    <p>
                      Dam Rehabilitation and Improvement Project (DRIP)
                      <i className="fa fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </a>
                <a href="">
                  <div className="card cl-2">
                    <div className="c-head">
                      <h5>250 Dams</h5>
                      <div className="img-p">
                        <img
                          src="images/c-2.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <h6>Identified for Rehabilitation</h6>
                    <p>
                      Under Dam Rehabilitation and Improvement Project (DRIP)
                      <i className="fa fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </a>
                <a href="">
                  <div className="card cl-3">
                    <div className="c-head">
                      <h5>215 Dams</h5>
                      <div className="img-p">
                        <img
                          src="images/c-3.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <h6>Under Construction</h6>
                    <p>
                      In India
                      <i className="fa fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </a>
                <a href="">
                  <div className="card cl-4">
                    <div className="c-head">
                      <h5>09 Agencies</h5>
                      <div className="img-p">
                        <img
                          src="images/c-4.png"
                          alt=""
                          className="img-fluid"
                        />
                      </div>
                    </div>
                    <h6>Participating from 07 States</h6>
                    <p>
                      In the project
                      <i className="fa fa-solid fa-arrow-right"></i>
                    </p>
                  </div>
                </a>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Welcome;
