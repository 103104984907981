import { Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import * as Yup from "yup";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  validateCaptcha,
  LoadCanvasTemplateNoReload,
  loadCaptchaAgain,
} from "react-simple-captcha";

const AadharVerificationForm = () => {
  const [verifyAdhar, setVerifyAdhar] = useState(false);
  const [captchaEl, setCaptchaEl] = useState("");
  // const captchaInputEl = useRef(null);
  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    aadharNo: "",
    captcha: "",
    otp: "",
  };

  // validation
  const validationSchema = Yup.object({
    role: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    otp: Yup.string().required("Required"),
  });
  // final submit formik form
  const handleSubmit = (values) => {
    const payload = {
      aadharNo: values.aadharNo,
      state: values.state,
    };
    alert(`${payload.role} and ${payload.state} and ${payload.firstname}`);
  };

  useEffect(() => {
    loadCaptchaEnginge(6);
  }, []);

  const handleOtp = () => {
    if (validateCaptcha(captchaEl)) {
      setVerifyAdhar(true);
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        //validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, touched, handleChange, errors, values }) => (
          // <form onSubmit={handleSubmit}>
          <form>
            <div className="row form-bg">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>Aadhar No</label>
                  <input
                    className="form-control"
                    id="aadharNo"
                    name="aadharNo"
                    value={values.aadharNo}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>Captcha</label>
                  <input
                    className="form-control"
                    id="captcha"
                    name="captcha"
                    value={values.captcha}
                    onChange={(e) => {
                      handleChange(e);
                      setCaptchaEl(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-2 ">
                <div className="form-group">
                  <label>Captcha Image</label>
                  <LoadCanvasTemplate reloadColor="red" reloadText="reload" />
                </div>{" "}
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-2 pt-4">
                <div className="form-group">
                  <button
                    className="blue-btn"
                    type="button"
                    onClick={handleOtp}
                  >
                    Get OTP
                  </button>
                </div>
              </div>
              {verifyAdhar && (
                <>
                  {" "}
                  <div className="col-12 col-sm-12 col-md-12 col-lg-2">
                    <div className="form-group">
                      <label>OTP</label>
                      <input
                        className="form-control"
                        id="otp"
                        name="otp"
                        value={values.otp}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 col-lg-2 pt-4">
                    <div className="form-group">
                      <button className="blue-btn" type="button">
                        Verify
                      </button>
                    </div>
                  </div>
                </>
              )}

              {/* <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center mt-5">
                <div className="form-group">
                  <button className="blue-btn" value={"Submit"} type="submit">
                    Submit
                  </button>
                </div>
              </div> */}
            </div>
            <hr/>
          </form>
          
        )}
      </Formik>
    </>
  );
};

export default AadharVerificationForm;
