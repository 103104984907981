import React from "react";
// import MainSlider from "./MainSlider";
import MapMediaSection from "./MapMediaSection/MapMediaSection";
// import Welcome from "./Welcome";
import WhatsNew from "./WhatsNew";
import MapMediaSectionCustom from "./MapMediaSection/MapMediaSectionCustom";

const Home = () => {
  return (
    <>
      {/* <MainSlider /> */}
      {/* <Welcome /> */}

      {/* <MapMediaSection /> */}
      <MapMediaSectionCustom />
      {/* <div className="new-layout">
        <WhatsNew />
        </div> */}

      {/* <div id="social-sidebar">
        <div className="social facebook">
          <a href="https://www.facebook.com/InAgrisearch/" target="_blank">
            <p>
              Like on Facebook <i className="fab fa-facebook-f"></i>
            </p>{" "}
          </a>
        </div>
        <div className="social youtube">
          <a href="https://www.youtube.com/user/icarindia" target="_blank">
            <p>
              Subscribe on Youtube <i className="fab fa-youtube"></i>
            </p>{" "}
          </a>
        </div>
        <div className="social twitter">
          <a href="https://twitter.com/icarindia" target="_blank">
            <p>
              Follow on Twitter <i className="fab fa-twitter"></i>
            </p>{" "}
          </a>
        </div>
      </div> */}
    </>
  );
};

export default Home;
