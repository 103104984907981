import React from "react";
import Header from "./Shared/Header/Header";
import Footer from "./Shared/Footer/Footer";
import AppRouting from "./AppRouting";
import SidebarComponent from "./Shared/Header/SidebarComponents";

const Layout = () => {
  return (
    <>
<div className="layour-wraper">
  <div>
      <Header />
      <div className="new-main-body">
        <SidebarComponent />
         <div className="new-welcome-bg">
          <AppRouting />
        </div>

        <div id="social-sidebar">
        <div className="social facebook">
          <a href="https://www.facebook.com/InAgrisearch/" target="_blank">
            <p>
              Like on Facebook <i className="fab fa-facebook-f"></i>
            </p>{" "}
          </a>
        </div>
        <div className="social youtube">
          <a href="https://www.youtube.com/user/icarindia" target="_blank">
            <p>
              Subscribe on Youtube <i className="fab fa-youtube"></i>
            </p>{" "}
          </a>
        </div>
        <div className="social twitter">
          <a href="https://twitter.com/icarindia" target="_blank">
            <p>
              Follow on Twitter <img src="\images\tw-icon.png" />
            </p>{" "}
          </a>
        </div>
      </div>
      </div>
      </div>
      <Footer />
      </div>
    </>
  );
};

export default Layout;
