import React from 'react'
import MainSlider from './Home/MainSlider'
import Welcome from './Home/Welcome'
import WhatsNew from './Home/WhatsNew'
import MapMediaSection from './Home/MapMediaSection/MapMediaSection'

const Landing = () => {
  return (
    <>
    <MainSlider />
    <Welcome />
    <div className="new-welcome-bg">
        <WhatsNew />
        <MapMediaSection />
      </div>
      <div id="social-sidebar">
        <div className="social facebook">
          <a href="https://www.facebook.com/InAgrisearch/" target="_blank">
            <p>
              Like on Facebook <i className="fab fa-facebook-f"></i>
            </p>{" "}
          </a>
        </div>
        <div className="social youtube">
          <a href="https://www.youtube.com/user/icarindia" target="_blank">
            <p>
              Subscribe on Youtube <i className="fab fa-youtube"></i>
            </p>{" "}
          </a>
        </div>
        <div className="social twitter">
          <a href="https://twitter.com/icarindia" target="_blank">
            <p>
              Follow on Twitter <i className="fab fa-twitter"></i>
            </p>{" "}
          </a>
        </div>
      </div>
    </>
  )
}

export default Landing
