import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extractErrorMessage } from "../../../utils";
import servicesMap from "./servicesMap";

const initialState = {
  stateList: {},
  longestHighestDamsDetails: {},
  damDetailsInfo: {},
  loading: false,
  error: "",
};

// 1> fetching state of highest dam
export const fetchStateList = createAsyncThunk(
  "sliceMap/fetchStateList",
  async (url, thunkAPI) => {
    try {
      return await servicesMap.fetchStateList(url);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);
// 2> fetching state of highest dam
export const fetchLongestHighestDamsDetails = createAsyncThunk(
  "sliceMap/fetchLongestHighestDamsDetails",
  async ({ url, payload }, thunkAPI) => {
    try {
      return await servicesMap.fetchLongestHighestDamsDetails(url, payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

// 3> fetching dam details info
export const fetchDamDetailsInfo = createAsyncThunk(
  "sliceMap/fetchDamDetailsInfo",
  async (url, thunkAPI) => {
    try {
      return await servicesMap.fetchDamDetailsInfo(url);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const sliceMap = createSlice({
  name: "sliceMap",
  initialState,
  reducers: {
    resetDams: (state) => {
      state.longestHighestDamsDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStateList.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchStateList.fulfilled, (state, action) => {
        state.stateList = action.payload;
        state.loading = false;
      })
      .addCase(fetchStateList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchLongestHighestDamsDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchLongestHighestDamsDetails.fulfilled, (state, action) => {
        state.longestHighestDamsDetails = action.payload;
        state.loading = false;
      })
      .addCase(fetchLongestHighestDamsDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    builder
      .addCase(fetchDamDetailsInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDamDetailsInfo.fulfilled, (state, action) => {
        state.damDetailsInfo = action.payload;
        state.loading = false;
      })
      .addCase(fetchDamDetailsInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetDams } = sliceMap.actions;
export default sliceMap.reducer;
