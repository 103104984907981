import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDamDetailsInfo } from "../../../../../../redux/slice/mapSlice/sliceMap"; 
import Spinner from "../../../../Components/Spinner";

const IndiaMap = () => {
  const [stateId, setStateId] = useState("");
  const { stateList, loading } = useSelector((state) => state.sliceMap);
  const dispatch = useDispatch();

  useEffect(() => {
    // First script
    const script = document.createElement("script");
    script.src = "./js/mapdata.js";
    script.async = true;
    // Second script
    const script2 = document.createElement("script");
    // script2.src = "./js/countrymapai.js";
    script2.src = "./js/countrymapNew.js";
    script2.async = true;

    document.head.appendChild(script);
    document.head.appendChild(script2);

    return () => {
      document.head.removeChild(script);
      document.head.removeChild(script2);
    };
  }, []);

  // if (loading) {
  //   return <Spinner />;
  // }

  return (
    <>
      <div>
        <select
          onChange={(e) => {
            console.log(e.target.value);
            window["getStateDistrictMap"](e.target.value);
            dispatch(
              fetchDamDetailsInfo(`get-home-data/${stateId}/${e.target.value}`)
            );
          }}
          id="dam_district_id"
        >
          <option value="">Select District</option>
        </select>
        <div>
          <select
            onChange={(e) => {
              if (e.target.value === '0') {
                window.location.reload(); // Reload the page
            }
            else{
          // console.log(e.target.value);
              window["getStateStatMap"](e.target.value);
              dispatch(fetchDamDetailsInfo(`get-home-data/${e.target.value}`));
              setStateId(e.target.value);
            }
            }}
            id="dam_state_id"
          >
            <option value="0">Select State</option>
            {stateList?.data?.states?.map((item, index) => (
              <option key={index} value={item.id}>
                {item.State_Name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className={`main-map-sec `}>
        <div className="india-map">
          <div id="map"></div>
        </div>
      </div>
    </>
  );
};

export default IndiaMap;
