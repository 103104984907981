import { axiosBaseURL } from "../../../apis/dharmaApi";

// fetch banners
const fetchRoleSelection = async (url) => {
  const response = await axiosBaseURL.get(url);
  return response.data;
};
// fetch banners
const fetchDistricts = async (url) => {
  const response = await axiosBaseURL.get(url);
  return response.data;
};

// fetch banners
const registerForm = async (url, payload) => {
  const response = await axiosBaseURL.post(url, payload);
  return response.data;
};

const servicesSignUp = {
  fetchRoleSelection,
  fetchDistricts,
  registerForm,
};

export default servicesSignUp;
