import { configureStore } from "@reduxjs/toolkit";
import sliceFooter from "../slice/sliceFooter";
import sliceHome from "../slice/homeSlice/sliceHome";
import sliceMap from "../slice/mapSlice/sliceMap";
import sliceSignUp from "../slice/SignUpSlice/sliceSignUp";
import SliceMapCustom from "../slice/mapSlice/SliceMapCustom";

export const store = configureStore({
  reducer: { sliceHome, sliceFooter, sliceMap,SliceMapCustom, sliceSignUp },
});
