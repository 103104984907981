import { axiosBaseURL } from "../../../apis/dharmaApi";

// fetch banners
const fetchHighestDam = async (url) => {
  const response = await axiosBaseURL.get(url);
  return response.data;
};

// fetch river name
const fetchHighestDamRiverName = async (url, id) => {
  const response = await axiosBaseURL.get(`${url}/${id}`);
  return response.data;
};
// fetch river name
const fetchLongestDamRiverName = async (url, id) => {
  const response = await axiosBaseURL.get(`${url}/${id}`);
  return response.data;
};
// fetch river name
const fetchLongestDamState = async (url, id) => {
  const response = await axiosBaseURL.get(`${url}/${id}`);
  return response.data;
};
// fetch river name
const fetchHighestDamState = async (url, id) => {
  const response = await axiosBaseURL.get(`${url}/${id}`);
  return response.data;
};
// fetch states list
const fetchStateList = async (url) => {
  const response = await axiosBaseURL.get(url);
  return response.data;
};

// fetch states list
const fetchLongestHighestDamsDetails = async (url, data) => {
  const response = await axiosBaseURL.post(url, data);
  return response.data;
};

// fetch states list
const fetchDamDetailsInfo = async (url) => {
  const response = await axiosBaseURL.get(url);
  return response.data;
};

const servicesMap = {
  fetchHighestDam,
  fetchHighestDamRiverName,
  fetchLongestDamRiverName,
  fetchLongestDamState,
  fetchHighestDamState,
  fetchStateList,
  fetchLongestHighestDamsDetails,
  fetchDamDetailsInfo,
};

export default servicesMap;
