import React, { useEffect } from "react";
import InnerBanner from "../../Components/InnerBanner";
import AadharVerificationForm from "./AadharVerificationForm";
import SignUpForm from "./SignUpForm";
const SignUp = () => {
  return (
    <>
      <div className="inner-pg">
        <InnerBanner title={"Sign Up"} />
        <div className="main-content-area">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 mb-3">
                <div className="title-area">
                  <div className="page-title">Sign Up</div>
                </div>
              </div>
            </div>
            <AadharVerificationForm />
            <SignUpForm />
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
