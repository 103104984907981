import React, { useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDistricts,
  fetchRoleSelection,
  registerForm,
  resetDistricts,
} from "../../../../redux/slice/SignUpSlice/sliceSignUp";
import "yup-phone";

const SignUpForm = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchRoleSelection("get-request-role-state"));
    return () => {
      dispatch(resetDistricts());
    };
  }, [dispatch]);

  const { rolesData, districtsList } = useSelector(
    (state) => state.sliceSignUp
  );

  //---------------- Formik ------------------------//

  // initial Values
  const initialValues = {
    role: "",
    firstname: "",
    middlename: "",
    lastname: "",
    email: "",
    mobile: "",
    state: "",
    district: "",
    pincode: "",
    full_address: "",
  };

  // validation
  const validationSchema = Yup.object({
    role: Yup.string().required("Required"),
    firstname: Yup.string()
      .max(50, "Required")
      .required("Required")
      .matches(/^[aA-zZ\s]+$/, "Required"),
    middlename: Yup.string().required("Required"),
    lastname: Yup.string().required("Required"),
    email: Yup.string().email("Required").required("Required"),
    mobile: Yup.string().required("Required"),
    state: Yup.string().required("Required"),
    district: Yup.string().required("Required"),
    pincode: Yup.string().required("Required"),
    full_address: Yup.string().required("Required"),
  });
  // final submit formik form
  const handleSubmit = (values, { resetForm }) => {
    console.log(values);
    dispatch(registerForm({ url: "submit-request-new-user", payload: values }));
    alert("Registered Successfully");
    resetForm();
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          errors,
          values,
          touched,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row form-bg">
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>Role</label>
                  <select
                    className="form-control"
                    id="role"
                    name="role"
                    value={values.role}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <option>Select Role</option>
                    {rolesData?.data?.roles?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                  {touched.role && errors.role ? (
                    <div className="error">{errors.role}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-4 ">
                <div className="form-group">
                  <label>State</label>
                  <select
                    className="form-control"
                    id="state"
                    name="state"
                    value={values.state}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      dispatch(
                        fetchDistricts(
                          `get-district-list-by-state/${e.target.value}`
                        )
                      );
                    }}
                  >
                    <option>Select State</option>
                    {rolesData?.data?.states?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.State_Name}
                      </option>
                    ))}
                  </select>
                  {touched.state && errors.state ? (
                    <div className="error">{errors.state}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>District</label>
                  <select
                    className="form-control"
                    id="district"
                    name="district"
                    value={values.district}
                    onBlur={handleBlur}
                    onChange={(e) => handleChange(e)}
                  >
                    <option>Select District</option>
                    {districtsList?.data?.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.District_Name}
                      </option>
                    ))}
                  </select>
                  {touched.district && errors.district ? (
                    <div className="error">{errors.district}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>First Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="firstname"
                    name="firstname"
                    value={values.firstname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.firstname && errors.firstname ? (
                    <div className="error">{errors.firstname}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>Middle Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="middlename"
                    name="middlename"
                    value={values.middlename}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.middlename && errors.middlename ? (
                    <div className="error">{errors.middlename}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>Last Name</label>
                  <input
                    className="form-control"
                    type="text"
                    id="lastname"
                    name="lastname"
                    value={values.lastname}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.lastname && errors.lastname ? (
                    <div className="error">{errors.lastname}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>Mobile Number</label>
                  <input
                    className="form-control"
                    type="text"
                    id="mobile"
                    name="mobile"
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.mobile && errors.mobile ? (
                    <div className="error">{errors.mobile}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>Email Id</label>
                  <input
                    className="form-control"
                    type="text"
                    id="email"
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.email && errors.email ? (
                    <div className="error">{errors.email}</div>
                  ) : null}
                </div>
              </div>

              <div className="col-12 col-sm-12 col-md-12 col-lg-4">
                <div className="form-group">
                  <label>Pin Code</label>
                  <input
                    className="form-control"
                    type="text"
                    id="pincode"
                    name="pincode"
                    value={values.pincode}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched.pincode && errors.pincode ? (
                    <div className="error">{errors.pincode}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-8">
                <div className="form-group">
                  <label>Full Address</label>
                  <textarea
                    className="form-control"
                    id="full_address"
                    name="full_address"
                    value={values.full_address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  ></textarea>
                  {touched.full_address && errors.full_address ? (
                    <div className="error">{errors.full_address}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-12 col-lg-12 text-center mt-5">
                <div className="form-group">
                  <button className="blue-btn" value={"Submit"} type="submit">
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </>
  );
};

export default SignUpForm;
