import React, { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { fetchGallery } from "../../../../redux/slice/homeSlice/sliceHome";

const PhotoSliderHome = () => {
  const dispatch = useDispatch();
  const slider = useRef();
  const { gallery } = useSelector((state) => state.sliceHome);
  const { REACT_APP_IMAGE_URL } = process.env;

  useEffect(() => {
    dispatch(fetchGallery("get-gallery"));
  }, [dispatch]);

  const settings = {
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    dots: false,
  };

  const next = () => {
    slider.current.slickNext();
  };
  const prev = () => {
    slider.current.slickPrev();
  };

  return (
    <div className="col-12">
      <div className="home-photo-section">
        <div className="page-title">Gallery</div>
        <div className="home-gallery-slider">
          <Slider {...settings} ref={slider}>
            {gallery?.data?.length > 0 ? (
              gallery.data.map((item, index) => (
                <div className="image-container" key={index}>
                  <img
                    src={`${REACT_APP_IMAGE_URL}${item.slider_image}`}
                    alt={item.title}
                  />
                  <div className="overlay-title">{item.title}</div>
                </div>
              ))
            ) : (
              <>No image found</>
            )}
          </Slider>
        </div>
        <div className="btnPause">
          <div className="arrow">
            <span className="left" onClick={prev}>
              <i className="fa fa-angle-left"></i>
            </span>
            <span className="right" onClick={next}>
              <i className="fa fa-angle-right"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhotoSliderHome;
