import React from "react";
import { useSelector } from "react-redux";

import Spinner from "../../Components/Spinner";

const DamDetailsInfoCustom = () => {
    const { loading, damDetailsInfo } = useSelector((state) => state.SliceMapCustom); 

    if (loading) {
      return <Spinner />;
    }
  return (
    <div id="map-details" className="map-details">
      {damDetailsInfo?.data && !Array.isArray(damDetailsInfo?.data) ? (
        <p
          dangerouslySetInnerHTML={{
            __html: damDetailsInfo.data,
            // .replace(/<img[^>]*>/g, ""),
          }}
        />
      ) : (
        <>No data found</>
      )}
    </div>
  )
}

export default DamDetailsInfoCustom
