import axios from "axios";

export const axiosBaseURL = axios.create({
  // baseURL: "http://164.52.201.69/cwcdharma/api/v1/",
  baseURL: "https://dharma.cwc.gov.in/dharma/api/v1/",

  headers: {
    "X-user": "zur1xjb4123ascvb",
  },
});
