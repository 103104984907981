import React, { useEffect } from "react";
import DamSlider from "../DamSlider";
import IndiaMap from "./IndiaMap/IndiaMap";
import {
  fetchStateList,
  resetDams,
  fetchLongestHighestDamsDetails,
  fetchDamDetailsInfo,
} from "../../../../../redux/slice/mapSlice/sliceMap";
import { useDispatch } from "react-redux";
import { fetchGallery } from "../../../../../redux/slice/homeSlice/sliceHome";
import DamDetailsInfo from "../DamDetailsInfo";

const MapMediaSection = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchGallery("get-gallery"));
    dispatch(fetchStateList("get-request-role-state"));
    dispatch(fetchDamDetailsInfo("get-home-data"));
    // dispatch(
    //   fetchLongestHighestDamsDetails({
    //     url: "get-project-location-list",
    //     payload: { state_id: 0 },
    //   })
    // );

    return () => {
      dispatch(resetDams());
    };
  }, [dispatch]);

  return (
    <>
      <div className="map-area">
        <div className="container">
          <div className="map-inner">
            <div className="row whitebg">
              <div className="col-12 col-sm-12 col-md-8 col-lg-8">
                <div className="row left-sec graybg">
                  <div className="map-sec">
                    <IndiaMap />
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-4 col-lg-4 dam-gallery">
                <div className="dam-info-wrapper">
                  <DamDetailsInfo />
                </div>
                {/* <DamSlider /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MapMediaSection;
