import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { fetchMenus } from "../../../redux/slice/homeSlice/sliceHome";
import PhotoSliderHome from "../../Container/pages/Home/PhotoSliderHome";

const SidebarComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchMenus("get-menu-list"));
  }, [dispatch]);

  const { menus } = useSelector((state) => state.sliceHome);

  return (
    <div className="logo-sec-wraper">
      <div className="container-fluid">
        <div className="row">
          {/* <div className="col-12 logo-sec wow fadeInLeft">
            <Link className="logo-align" to="/">
              <img src="images/logo.png" alt="emblem" />
              <div className="brand-text">
                <h4>
                  DHARMA
                  <span>
                    Dam Health And Rehabilitation Monitoring Application
                  </span>
                </h4>
              </div>
            </Link>
          </div> */}
          <div className="nsla-logo-sec">
            <Link className="logo-align" to="/">
              <img src="images/nsla_logo.png" alt="nsla logo" />
              <div className="brand-text">
                <h4>
                  NDSA
                  <span className="ndsa-txt1">
                    National Dam Safety Authority
                  </span>
                  <span className="ndsa-txt2">
                    Department of Water Resources, RD&GR
                  </span>
                  <span className="ndsa-txt3">
                    Ministry of Jal Shakti, Government of India
                  </span>
                </h4>
              </div>
            </Link>
          </div>

          <div className="col-12 loginbtn-sec wow fadeInRight">
            <div className="nav-wraper">
              <nav className="navbar navbar-expand-xl navbar-light custom-nav w-100 wow fadeInUp">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarSupportedContent"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                      <NavLink
                        to="/"
                        className="nav-link"
                        // activeClassName="active"
                      >
                        Home
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/about-us"
                        className="nav-link"
                        // activeClassName="active"
                      >
                        About Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/contact-us"
                        className="nav-link"
                        // activeClassName="active"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/sdso"
                        className="nav-link"
                        // activeClassName="active"
                      >
                        SDSO
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink
                        to="/dams"
                        className="nav-link"
                        // activeClassName="active"
                      >
                        Specified Dams
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/download" className="nav-link">
                        Download
                      </NavLink>
                    </li>
                    <li className="nav-item">
                      <NavLink to="/related-links" className="nav-link">
                        Related Sites
                      </NavLink>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>

          {/* photo-slider */}
          <PhotoSliderHome />
        </div>
      </div>
    </div>
  );
};

export default SidebarComponent;
