import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { extractErrorMessage } from "../../../utils";
import servicesSignUp from "./serviceSignUp";

const initialState = {
  rolesData: {},
  districtsList: {},
  error: "",
};

// 1> fetching roles and state
export const fetchRoleSelection = createAsyncThunk(
  "sliceSignUp/fetchRoleSelection",
  async (url, thunkAPI) => {
    try {
      return await servicesSignUp.fetchRoleSelection(url);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

// 2> fetch districts
export const fetchDistricts = createAsyncThunk(
  "sliceSignUp/fetchDistricts",
  async (url, thunkAPI) => {
    try {
      return await servicesSignUp.fetchDistricts(url);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

// 2> Submit form
export const registerForm = createAsyncThunk(
  "sliceSignUp/registerForm",
  async ({ url, payload }, thunkAPI) => {
    try {
      return await servicesSignUp.registerForm(url, payload);
    } catch (error) {
      return thunkAPI.rejectWithValue(extractErrorMessage(error));
    }
  }
);

export const sliceSignUp = createSlice({
  name: "sliceSignUp",
  initialState,
  reducers: {
    resetDistricts: (state) => {
      state.districtsList = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoleSelection.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchRoleSelection.fulfilled, (state, action) => {
        state.rolesData = action.payload;
        state.loading = false;
      })
      .addCase(fetchRoleSelection.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
    builder
      .addCase(fetchDistricts.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchDistricts.fulfilled, (state, action) => {
        state.districtsList = action.payload;
        state.loading = false;
      })
      .addCase(fetchDistricts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { resetDistricts } = sliceSignUp.actions;
export default sliceSignUp.reducer;
