import React from "react";
import FooterBottom from "./FooterBottom";
import WhatsNew from "../../Container/pages/Home/WhatsNew";

// import FooterSlider from "./FooterSlider";

const Footer = () => {
  return (
    <>
      <footer>
        {/* <FooterSlider /> */}
        <div className="new-layout">
          <div className="news-area graybg">
            <WhatsNew />
          </div>
        </div>
        <FooterBottom />
      </footer>
    </>
  );
};

export default Footer;
