import React, { useEffect } from "react";
import InnerBanner from "../Components/InnerBanner";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchPageData,
  resetMenuData,
} from "../../../redux/slice/homeSlice/sliceHome";
import Spinner from "../Components/Spinner";
import { useNavigate } from "react-router-dom";

const Contact = ({ title, id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(fetchPageData(`get-page-details/${id}`));
    return () => {
      dispatch(resetMenuData());
    };
  }, [dispatch, id]);

  const { pageData, loading } = useSelector((state) => state.sliceHome);
  return (
    <>
      <div className="inner-pg">
        <InnerBanner
          title={title}
          // action={() => (
          //   <button onClick={() => alert("Button Clicked")}>Some Action</button>
          // )}
          // action2={
          //   <button onClick={() => alert("Button Clicked")}>Some Action</button>
          // }
        />

        <div className="main-content-area">
          <div className="container">
            <div className="row">
              {pageData && (
                <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                  <div className="title-area">
                    <div className="container">
                      <div className="row">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                          <div className="page-title">{title}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="inner-area">
                    <div className="col-12 col-sm-12 col-md-12 col-lg-12">
                      <p
                        dangerouslySetInnerHTML={{
                          __html: pageData.data && pageData.data.content,
                        }}
                      />
                    </div>
                    
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
