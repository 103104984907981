import React from "react";
import Menus from "./Menus";
import TopHeader from "./TopHeader";

const Header = () => {
  return (
    <>
      <header>
        <TopHeader />
        {/* <Menus /> */}
      </header>
    </>
  );
};

export default Header;
